















































































































































































































































































































































































































































































































































































































































import * as R from 'ramda';
import { defineComponent, ref, reactive, computed } from '@vue/composition-api';
import { OrbitSpinner } from 'epic-spinners';
import { ValidationProvider, extend, ValidationObserver } from 'vee-validate';
import { required, min, confirmed, max } from 'vee-validate/dist/rules';
import { useAxios } from '@vue-composable/axios';
import { useQuery, useResult } from '@/app/composable';
import { FormBlock, TwButton, ConfirmModal, Tabs, SvgImage, Scrollbar } from '@/app/components';
import Keycloak from '@/modules/auth/api/keycloak';
import store from '@/app/store';
import { tokenScopes } from '@/app/constants/scopes';
import { RunnerAPI } from '@/modules/data-checkin/api';
import GET_USER_DEPARTMENTS from '../graphql/getUserDepartments.graphql';
import { UsersAPI, AccessTokenAPI } from '../api';
import AccessTokens from './AccessTokens.vue';
import RunnerTokens from './RunnerTokens.vue';
import UserPreferences from '../components/UserPreferences.vue';
import { UserRoles } from '@/app/constants';

extend('required', {
    ...required,
    message: '{_field_} is required',
});

extend('min', {
    ...min,
    message: '{_field_} must be longer than or equal to 8 characters.',
});
extend('max', {
    ...max,
    message: '{_field_} must be less than or equal to 24 characters.',
});
extend('confirmed', { ...confirmed, message: 'Repeat Password does not match.' });

export default defineComponent({
    name: 'EditUser',
    components: {
        FormBlock,
        ValidationProvider,
        ValidationObserver,
        OrbitSpinner,
        TwButton,
        ConfirmModal,
        Tabs,
        AccessTokens,
        RunnerTokens,
        SvgImage,
        Scrollbar,
        UserPreferences,
    },
    setup(props, { root }) {

        const isAdmin = computed(() =>  !!store.getters.auth.hasRole(UserRoles.Admin)).value;
        const { exec, loading, error } = useAxios();
        const user = ref(store.state.auth.user);
        const tokenToCopy = ref<any>(null);
        const hostToCopy = ref<any>(null);
        const removeToken = ref(false);
        const showDeleteTokenModal = ref(false);
        const tokenToBeDeleted = ref(null);
        const useKeycloak = computed(() => Keycloak.isEnabled());
        
        const tabs = ref([
            { id: 0, title: 'User Profile' },
            { id: 1, title: 'Notifications' },
            { id: 2, title: 'Access Tokens' },
        ]);
        const activeTab = ref(0);

        const createToken = ref(false);
        const generatedToken = ref(false);
        const tokenGeneration: any = reactive({
            name: null,
            token: '',
            scopes: [],
        });
        const accessTokens: any = ref([]);

        const registerRunner = ref(false);
        const registeredRunner = ref(false);
        const runnerRegistration: any = reactive({
            name: null,
            token: '',
        });
        const runners: any = ref([]);

        const totalScopes = ref(R.clone(tokenScopes));

        exec(AccessTokenAPI.retrieveTokens())
            .then((res: any) => {
                for (let i = 0; i <= res.data.length; i += 1) {
                    if (res.data[i]) {
                        const token = {
                            name: res.data[i].name,
                            id: res.data[i].id,
                            scopes: res.data[i].scopes,
                        };

                        accessTokens.value.push(token);
                    }
                }
            })
            .catch(() => {
                (root as any).$toastr.e('An error occured.', 'Error');
            });

        exec(RunnerAPI.all())
            .then((res: any) => {
                for (let i = 0; i <= res.data.length; i += 1) {
                    if (res.data[i]) {
                        const runner = {
                            name: res.data[i].name,
                            id: res.data[i].id,
                            framworks: res.data[i].frameworks,
                        };

                        runners.value.push(runner);
                    }
                }
            })
            .catch(() => {
                (root as any).$toastr.e('An error occured.', 'Error');
            });

        const userRef = ref<any>(null);
        const userUpdateDetails = reactive({
            firstName: user.value.firstName,
            lastName: user.value.lastName,
        });
        const passwordChange = reactive({ password: null, newPassword: null, repeatPassword: null });
        const passwordRef = ref<any>(null);
        const { loading: departmentsLoading, error: departmentsError, result } = useQuery(
            GET_USER_DEPARTMENTS,
            { id: user.value.id },
            { fetchPolicy: 'no-cache' },
        );
        const departments = useResult(result, null, (data: any) => data.userDepartments);

        const defaultPreferences: any = ref({});
        let cloneDefaultPreferences: any = ref({});

        //Get user preferences
        exec(UsersAPI.getUserPreferences())
            .then((res: any) => {
                defaultPreferences.value = res.data;
                const notificationReferenceType = {
                    dcj: {
                        label: 'Data Collection Jobs',
                        value: {},
                    },
                };

                if(isAdmin){
                    notificationReferenceType["concept"] = {
                        label: 'Data Model Management',
                        value: {},
                    };
                    notificationReferenceType["workflow"] = {
                        label: 'Workflow Executions',
                        value: {},
                    };
                }
                //group events by reference type. Model Suggestions, Data Checkin Jobs, Workflows
                if (defaultPreferences.value?.notifications) {
                    Object.keys(defaultPreferences.value.notifications).forEach((eventKey) => {
                        const referenceType = eventKey.split('.')[0];
                        if (notificationReferenceType[referenceType]) {
                            notificationReferenceType[referenceType].value[eventKey] =
                                defaultPreferences.value.notifications[eventKey];
                        }
                    });
                }

                defaultPreferences.value = notificationReferenceType;
                // clone variable needed to track changes on the preferences and make the save button available
                cloneDefaultPreferences.value = R.clone(defaultPreferences.value);
            })
            .catch(() => {
                (root as any).$toastr.e('An error occured.', 'Error');
            });

        // Methods

        const changePassword = async () => {
            const valid = await passwordRef.value.validate();
            if (valid) {
                try {
                    const { exec: execUpdate } = useAxios(true);
                    await execUpdate(UsersAPI.changePassword(passwordChange));

                    (root as any).$toastr.s('Password has been changed successfuly', 'Success');
                } catch (e) {
                    (root as any).$toastr.e('Invalid password', 'Error');
                } finally {
                    passwordChange.password = null;
                    passwordChange.newPassword = null;
                    passwordChange.repeatPassword = null;

                    passwordRef.value.reset();
                }
            }
        };

        const BACKEND_URL = process.env.VUE_APP_BACKEND_URL;

        const saveChanges = async () => {
            const valid = userRef.value ? await userRef.value.validate() : true;

            if (valid) {
                user.value = { ...user.value, ...userUpdateDetails };
                try {
                    // ungroup events. Now the object is divided by reference type but we need preferences individually
                    const updatedUserPreferences = Object.keys(defaultPreferences.value).reduce((p: any, c: any) => {
                        return { ...p, ...defaultPreferences.value[c].value };
                    }, {});

                    // update preferences to db
                    exec(
                        UsersAPI.updateUserPreferences({
                            notifications: R.mergeAll(updatedUserPreferences),
                        }),
                    );
                    cloneDefaultPreferences.value = R.clone(defaultPreferences.value);

                    // if there is also change on user details update them too
                    if (userRef.value) {
                        exec(UsersAPI.updateUser(user.value));
                        store.commit.auth.SET_USER(user.value);
                    }

                    (root as any).$toastr.s('User data have been changed successfuly', 'Success');
                } catch (e) {
                    (root as any).$toastr.e('Changing user data failed', 'Error');
                }
            }
        };

        const checkDifference = computed(() => {
            return (
                userUpdateDetails.firstName === user.value.firstName &&
                userUpdateDetails.lastName === user.value.lastName &&
                JSON.stringify(cloneDefaultPreferences.value) === JSON.stringify(defaultPreferences.value)
            );
        });

        const cancel = async () => {
            root.$router.go(-1);
        };

        const showTokenGeneration = () => {
            createToken.value = true;
        };

        const showRunnerRegistration = () => {
            registerRunner.value = true;
        };

        const generateToken = () => {
            for (let i = 0; i < totalScopes.value.length; i += 1) {
                if (totalScopes.value[i].checked) {
                    tokenGeneration.scopes.push(totalScopes.value[i].name);
                }
            }
            const payload = {
                name: tokenGeneration.name,
                scopes: tokenGeneration.scopes,
                createdById: 0,
            };

            exec(AccessTokenAPI.generateToken(payload))
                .then((res: any) => {
                    tokenGeneration.token = res.data.key;

                    generatedToken.value = true;
                    createToken.value = false;
                    tokenGeneration.name = null;
                    tokenGeneration.scopes = [];
                })
                .catch(() => {
                    (root as any).$toastr.e('The token could not be generated due to an error.', 'Error');
                });
        };

        const generateRunnerToken = () => {
            const payload = {
                name: runnerRegistration.name,
            };

            exec(RunnerAPI.generateToken(payload))
                .then((res: any) => {
                    runnerRegistration.token = res.data.token;

                    registeredRunner.value = true;
                    registerRunner.value = false;
                    runnerRegistration.name = null;
                })
                .catch(() => {
                    (root as any).$toastr.e('The token could not be generated due to an error.', 'Error');
                });
        };

        const cancelTokenGeneration = () => {
            createToken.value = false;
            tokenGeneration.name = null;
        };

        const cancelRunnerRegistration = () => {
            registerRunner.value = false;
            runnerRegistration.name = null;
        };

        const copyToClipboard = () => {
            tokenToCopy.value = document.querySelector('#token');
            if (tokenToCopy.value.value) {
                tokenToCopy.value.setAttribute('type', 'text');
                tokenToCopy.value.select();
                document.execCommand('copy');
                tokenToCopy.value.setAttribute('type', 'hidden');
            }
        };

        const copyHostToClipboard = () => {
            hostToCopy.value = document.querySelector('#host');
            if (hostToCopy.value.value) {
                hostToCopy.value.setAttribute('type', 'text');
                hostToCopy.value.select();
                document.execCommand('copy');
                hostToCopy.value.setAttribute('type', 'hidden');
            }
        };

        const deleteToken = (id: number) => {
            exec(AccessTokenAPI.deleteToken(id))
                .then(() => {
                    accessTokens.value = [];
                    exec(AccessTokenAPI.retrieveTokens())
                        .then((res: any) => {
                            for (let i = 0; i <= res.data.length; i += 1) {
                                if (res.data[i]) {
                                    const token = {
                                        name: res.data[i].name,
                                        id: res.data[i].id,
                                        scopes: res.data[i].scopes,
                                    };

                                    accessTokens.value.push(token);
                                }
                            }
                        })
                        .catch(() => {
                            (root as any).$toastr.e('An error occured.', 'Error');
                        });

                    tokenToBeDeleted.value = null;
                    createToken.value = false;
                    showDeleteTokenModal.value = false;
                })
                .catch(() => {
                    (root as any).$toastr.e('The token could not be deleted due to an error.', 'Error');
                });
        };

        const showScopes = (scopes: any) => {
            let finalScopes = '';
            for (let i = 0; i < scopes.length; i += 1) {
                if (i === scopes.length - 1) {
                    finalScopes += scopes[i];
                } else {
                    finalScopes += `${scopes[i]}, `;
                }
            }

            return finalScopes;
        };

        const addToList = (name: any) => {
            for (let i = 0; i < totalScopes.value.length; i += 1) {
                if (totalScopes.value[i].name === name) {
                    totalScopes.value[i].checked = !totalScopes.value[i].checked;
                }
            }
        };

        const checkScopes = computed(() => {
            for (let i = 0; i < totalScopes.value.length; i += 1) {
                if (totalScopes.value[i].checked) {
                    return false;
                }
            }
            return true;
        });

        // Temp binaries - will be fixed once the binary release CI is finished and backend is setup to serve them statically
        const binaries = {
            tray: [
                {
                    os: 'Windows',
                    name: 'runner',
                    link: 'https://develop.s5labs.eu:19000/ope-binaries/v3.0.1/rc-runner_3.0.1_windows_x86_64.zip',
                },
                {
                    os: 'MacOS',
                    name: 'runner',
                    link: 'https://develop.s5labs.eu:19000/ope-binaries/v3.0.1/rc-runner_3.0.1_darwin_x86_64.zip',
                },
                {
                    os: 'Linux',
                    name: 'runner',
                    link: 'https://develop.s5labs.eu:19000/ope-binaries/v3.0.1/rc-runner_3.0.1_linux_x86_64.zip',
                },
            ],
            headless: [
                {
                    os: 'Windows',
                    name: 'runner',
                    link:
                        'https://develop.s5labs.eu:19000/ope-binaries/v3.0.1/rc-runner_3.0.1_windows_x86_64_headless.zip',
                },
                {
                    os: 'MacOS',
                    name: 'runner',
                    link:
                        'https://develop.s5labs.eu:19000/ope-binaries/v3.0.1/rc-runner_3.0.1_darwin_x86_64_headless.zip',
                },
                {
                    os: 'Linux',
                    name: 'runner',
                    link:
                        'https://develop.s5labs.eu:19000/ope-binaries/v3.0.1/rc-runner_3.0.1_linux_x86_64_headless.zip',
                },
                {
                    os: 'Edge',
                    name: 'runner',
                    link:
                        'https://develop.s5labs.eu:19000/ope-binaries/v3.0.1/rc-runner_3.0.1_linux_arm_headless_edge.zip',
                },
            ],
        };

        const tabClicked = (idx: number) => {
            activeTab.value = idx;
        };

        return {
            user,
            loading,
            error,
            departments,
            passwordChange,
            changePassword,
            saveChanges,
            userUpdateDetails,
            cancel,
            departmentsLoading,
            departmentsError,
            userRef,
            passwordRef,
            tabs,
            activeTab,
            generatedToken,
            showTokenGeneration,
            tokenGeneration,
            cancelTokenGeneration,
            generateToken,
            createToken,
            copyToClipboard,
            copyHostToClipboard,
            tokenToCopy,
            hostToCopy,
            deleteToken,
            removeToken,
            showDeleteTokenModal,
            accessTokens,
            tokenToBeDeleted,
            showScopes,
            totalScopes,
            addToList,
            checkDifference,
            checkScopes,
            tabClicked,
            runners,
            cancelRunnerRegistration,
            registerRunner,
            registeredRunner,
            generateRunnerToken,
            showRunnerRegistration,
            runnerRegistration,
            BACKEND_URL,
            binaries,
            useKeycloak,
            defaultPreferences,
        };
    },
});
